import React, { useState } from "react";
import { useForm } from "react-hook-form";


  const data = [
    ["项目名称", "project_name", "text", [], "", "1", "一些说明"],
    ["报告日期", "report_submission_date", "date", [], "", "0", "一些说明"],
    ["图纸比例", "drawing_scale", "select", ["1:500", "1:1000", "1:2000", "1:5000"], "1:500", "0", "一些说明"],
    ["任务来源", "task_source", "textarea", [], "这里输入任务来源部分…………", "0", "一些说明"],
    //["技术资料", "technical_materials_company_1", "textarea", [], "XXX公司XXX日期编制的XXX...\n每行一条，回车换行，不要加序号", "0", "一些说明"],
    ["技术资料", "technical_materials_company", "textarea", [], "XXX公司XXX日期编制的《勘界报告》及图件...\n按格式每行一条，回车换行，不要加序号", "0", "第一行必须是勘界报告及图件"],
    ["任务省", "task_province", "select", ["山西省"], "山西省", "0", "一些说明"],
    ["任务市", "task_city", "select", ["晋城市"], "晋城市", "0", "一些说明"],
    ["任务县", "task_county", "select", ["泽州", "高平", "陵川", "阳城", "沁水", "城区"], "陵川", "0", "一些说明"],
    ["任务乡镇、街道", "task_town", "textarea", [], "", "0", "一些说明"],
    ["易发灾种", "disaster_type", "checkbox", ["采空塌陷", "崩塌", "滑坡", "不稳定斜坡", "泥石流", "地裂缝", "岩溶塌陷", "地面沉降"], "", "0", "一些说明"],
    ["易发程度", "disaster_level", "select", ["高易发区", "中易发区", "低易发区"], "高易发区", "0", "一些说明"],
    ["位置", "task_location", "text", [], "xxx村北部2Km", "0", "一些说明"],
    ["地理位置东经", "task_east_longitude", "text", [], "度分秒~度分秒", "0", "一些说明"],
    ["地理位置北纬", "task_north_latitude", "text", [], "度分秒~度分秒", "0", "一些说明"],
    ["占地面积", "land_acquisition_area", "number", [], "", "0", "单位：平方米"],
    ["建设内容", "construction_content", "textarea", [], "", "0", "一些说明"],
    ["交通位置图", "traffic_location_diagram", "file", [], "", "0", "这里待处理"],
    ["总平面布置图", "general_layout_plan", "file", [], "", "0", "一些说明"],
    ["征地拐点坐标", "turning_point_coordinate", "textarea", [], "例：\nx1,y1\n38000000,3900000", "0", "8位（6位）经度在前，每行一个坐标，不要加序号"],
    ["评估开始日期", "assessment_start_date", "date", [], "", "0", "一些说明"],
    ["评估结束日期", "assessment_end_date", "date", [], "", "0", "一些说明"],
    ["调查区面积", "survey_area", "number", [], "", "0", "单位：平方米"],
    ["评估区面积", "assessment_area", "number", [], "", "0", "单位：平方米"],
    ["调查控制点数量", "survey_control_point_quantity", "number", [], "", "0", "一些说明"],
    ["现场照片张数", "photo_quantity", "number", [], "", "0", "一些说明"],
    ["评估范围依据", "assessment_scope", "textarea", [], "", "0", "一些说明"],
    ["区域地质背景描述", "regional_geological_background", "textarea", [], "", "0", "一些说明"],
    ["区域地质背景复杂程度", "regional_geological_background_complexity", "select", ["简单", "中等", "复杂"], "", "0", "一些说明"],
    ["地形地貌描述", "terrain_landform", "textarea", [], "", "0", "一些说明"],
    ["地形地貌复杂程度", "terrain_landform_complexity", "select", ["简单", "中等", "复杂"], "", "0", "一些说明"],
    ["地层岩性工程性质描述", "stratum_lithology_and_engineering_properties", "textarea", [], "", "0", "一些说明"],
    ["地层岩性工程性质复杂程度", "stratum_lithology_and_engineering_properties_complexity", "select", ["简单", "中等", "复杂"], "", "0", "一些说明"],
    ["地质构造描述", "geological_structure", "textarea", [], "", "0", "一些说明"],
    ["地质构造复杂程度", "geological_structure_complexity", "select", ["简单", "中等", "复杂"], "", "0", "一些说明"],
    ["含水层描述", "aquifer", "textarea", [], "", "0", "一些说明"],
    ["含水层复杂程度", "aquifer_complexity", "select", [], "", "0", "一些说明"],
    ["地质灾害及不良地质现象描述", "geological_hazard_and_adverse_geological_phenomena", "textarea", [], "textarea", "0", "一些说明"],
    ["地质灾害及不良地质现象复杂程度", "geological_hazard_and_adverse_geological_phenomena_complexity", "select", ["简单", "中等", "复杂"], "", "0", "一些说明"],
    ["人类活动描述", "human_activity", "textarea", [], "", "0", "一些说明"],
    ["人类活动复杂程度", "human_activity_complexity", "select", ["简单", "中等", "复杂"], "", "0", "一些说明"],
    ["项目重要性", "human_activity_complexity", "select", ["一般", "较重要", "重要"], "", "0", "一些说明"],
    ["工程类型", "engineering_type", "text", [], "", "0", "一些说明"],
    ["最大高度", "maximum_height", "number", [], "", "0", "单位：米"],
    ["最大跨度", "maximum_span", "number", [], "", "0", "单位：米"],
    ["评估灾种", "assessed_disaster_type", "checkbox", ["采空塌陷", "崩塌", "滑坡", "不稳定斜坡", "泥石流", "地裂缝", "岩溶塌陷", "地面沉降"], "", "0", "一些说明"],
    ["地质构造图", "geological_structure_diagram", "file", [], "", "0", "一些说明"],
    ["水系分布图", "water_system_distribution_diagram", "file", [], "", "0", "一些说明"],
    ["地形地貌图1", "terrain_landform_picture_var1", "file", [], "", "0", "一些说明"],
    ["地形地貌图2", "terrain_landform_picture_var2", "file", [], "", "0", "一些说明"],
    ["地形地貌图3", "terrain_landform_picture_var3", "file", [], "", "0", "一些说明"],
    ["地形地貌图4", "terrain_landform_picture_var4", "file", [], "", "0", "一些说明"],
    ["地层岩性", "stratum_lithology", "checkbox", ["第四系全新统（Q4）", "第四系上统马兰组（Q3m）", "二叠系中统上石盒子组（P2s）", "二叠系下统下石盒子组（P2x）", "二叠系下统山西组（P1s）", "石炭系上统太原组（C3t）", "石炭系中统本溪组（C2b）", "奥陶系中统峰峰组（O2f）", "奥陶系中统上马家沟组（O2s）", "奥陶系中统下马家沟组（O2x）", "寒武系中统张夏组（∈2z）", "寒武系下统馒头组（∈1m）", "太古界（Ar）"], "", "0", "一些说明"],
    ["地下水补给、径流、排泄", "groundwater_recharge_runoff_and_discharge_conditions", "textarea", [], "", "0", "一些说明"],
    ["地质灾害类型特征（现状）", "geological_hazard_identification_and_evaluation", "textarea", [], "", "0", "一些说明"],
	//现状评估
    //滑坡
    ["滑坡诱发因素", "landslide_trigger_factors", "checkbox", ["地下水位上升","降水","融雪","开挖扰动","地震","采矿","新构造运动","融冰", "爆破", "河流侵蚀", "加载", "抽排水", "沟渠溢流或渗水"], "", "0", "一些说明"],
    ["滑坡威胁对象", "landslide_threat_project", "text", [], "过往行人及车辆", "0", "一些说明"],
    ["滑坡威胁金额", "landslide_threat_mount", "number", [], "10", "0", "万元"],
    ["滑坡威胁人数", "landslide_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["滑坡发育程度", "landslide_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //崩塌
    ["崩塌诱发因素", "collapse_trigger_factors", "checkbox", ["温差变化", "降水", "融雪", "河流侵蚀", "地震", "融冰", "树木根劈","开挖扰动", "爆破", "机械震动", "抽排水", "加载", "沟渠溢流或渗水"], "", "0", "一些说明"],
    ["崩塌威胁对象", "collapse_threat_project", "text", [], "过往行人及车辆", "0", "一些说明"],
    ["崩塌威胁金额", "collapse_threat_mount", "number", [], "10", "0", "万元"],
    ["崩塌威胁人数", "collapse_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["崩塌发育程度", "collapse_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //采空塌陷
    ["采空塌陷诱发因素", "mine_subsidence_trigger_factors", "checkbox", ["地下水位变化", "地震","采矿", "抽排水", "开挖扰动", "震动", "加载"], "", "0", "一些说明"],
    ["采空塌陷威胁对象", "mine_subsidence_threat_project", "text", [], "过往行人及车辆", "0", "一些说明"],
    ["采空塌陷威胁金额", "mine_subsidence_threat_mount", "number", [], "10", "0", "万元"],
    ["采空塌陷威胁人数", "mine_subsidence_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["采空塌陷发育程度", "mine_subsidence_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //不稳定斜坡
    ["不稳定斜坡诱发因素", "unstable_slope_trigger_factors", "checkbox", ["地下水位上升", "降水", "融雪", "开挖扰动", "地震", "采矿", "新构造运动", "抽排水", "爆破", "河流侵蚀", "树木根劈", "融冰", "沟渠溢流或渗水", "温差变化", "加载", "机械震动"], "", "0", "一些说明"],
    ["不稳定斜坡威胁对象", "unstable_slope_threat_project", "text", [], "过往行人及车辆", "0", "一些说明"],
    ["不稳定斜坡威胁金额", "unstable_slope_threat_mount", "number", [], "10", "0", "万元"],
    ["不稳定斜坡威胁人数", "unstable_slope_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["不稳定斜坡发育程度", "unstable_slope_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //泥石流
    ["泥石流诱发因素", "debris_flow_trigger_factors", "checkbox", ["弃渣加载", "融雪", "融冰", "堰塞湖溢流", "地震","水库溢流或垮坝", "沟渠溢流", "降水", "植被破坏"], "", "0", "一些说明"],
    ["泥石流威胁对象", "debris_flow_threat_project", "text", [], "过往行人及车辆", "0", "一些说明"],
    ["泥石流威胁金额", "debris_flow_threat_mount", "number", [], "10", "0", "万元"],
    ["泥石流威胁人数", "debris_flow_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["泥石流发育程度", "debris_flow_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //岩溶塌陷
    ["岩溶塌陷诱发因素", "karst_subside_trigger_factors", "checkbox", ["地下水位变化", "地震", "降水","抽排水", "开挖扰动", "采矿", "机械震动", "加载"], "", "0", "一些说明"],
    ["岩溶塌陷威胁对象", "karst_subside_threat_project", "text", [], "过往行人及车辆", "0", "一些说明"],
    ["岩溶塌陷威胁金额", "karst_subside_threat_mount", "number", [], "10", "0", "万元"],
    ["岩溶塌陷威胁人数", "karst_subside_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["岩溶塌陷发育程度", "karst_subside_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //地裂缝
    ["地裂缝诱发因素", "ground_fissure_trigger_factors", "checkbox", ["地震", "新构造运动","抽排水"], "", "0", "一些说明"],
    ["地裂缝威胁对象", "ground_fissure_threat_project", "text", [], "过往行人及车辆", "0", "一些说明"],
    ["地裂缝威胁金额", "ground_fissure_threat_mount", "number", [], "10", "0", "万元"],
    ["地裂缝威胁人数", "ground_fissure_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["地裂缝发育程度", "ground_fissure_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //地面沉降
    ["地面沉降诱发因素", "subsidence_trigger_factors", "checkbox", ["新构造运动","抽排水","油气开采"], "", "0", "一些说明"],
    ["地面沉降威胁对象", "subsidence_threat_project", "text", [], "过往行人及车辆", "0", "一些说明"],
    ["地面沉降威胁金额", "subsidence_threat_mount", "number", [], "10", "0", "万元"],
    ["地面沉降威胁人数", "subsidence_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["地面沉降发育程度", "subsidence_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    ["地质灾害类型特征（预测）", "prediction_situation_evaluation", "textarea", [], "", "0", "一些说明"],
    //引发评估
    //引发滑坡
    ["引发滑坡诱发因素", "trigger_landslide_trigger_factors", "checkbox", ["地下水位上升","降水","融雪","开挖扰动","地震","采矿","新构造运动","融冰", "爆破", "河流侵蚀", "加载", "抽排水", "沟渠溢流或渗水"], "", "0", "一些说明"],
    ["引发滑坡威胁对象", "trigger_landslide_threat_project", "text", [], "过往行人及车辆", "0", "一些说明"],
    ["引发滑坡威胁金额", "trigger_landslide_threat_mount", "number", [], "10", "0", "万元"],
    ["引发滑坡威胁人数", "trigger_landslide_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["引发滑坡发育程度", "trigger_landslide_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //引发崩塌
    ["引发崩塌诱发因素", "trigger_collapse_trigger_factors", "checkbox", ["温差变化", "降水", "融雪", "河流侵蚀", "地震", "融冰", "树木根劈","开挖扰动", "爆破", "机械震动", "抽排水", "加载", "沟渠溢流或渗水"], "", "0", "一些说明"],
    ["引发崩塌威胁对象", "trigger_collapse_threat_project", "text", [], "过往行人及车辆", "0", "一些说明"],
    ["引发崩塌威胁金额", "trigger_collapse_threat_mount", "number", [], "10", "0", "万元"],
    ["引发崩塌威胁人数", "trigger_collapse_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["引发崩塌发育程度", "trigger_collapse_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //引发采空塌陷
    ["引发采空塌陷诱发因素", "trigger_mine_subsidence_trigger_factors", "checkbox", ["地下水位变化", "地震","采矿", "抽排水", "开挖扰动", "震动", "加载"], "", "0", "一些说明"],
    ["引发采空塌陷威胁对象", "trigger_mine_subsidence_threat_project", "text", [], "过往行人及车辆", "0", "一些说明"],
    ["引发采空塌陷威胁金额", "trigger_mine_subsidence_threat_mount", "number", [], "10", "0", "万元"],
    ["引发采空塌陷威胁人数", "trigger_mine_subsidence_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["引发采空塌陷发育程度", "trigger_mine_subsidence_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //引发不稳定斜坡
    ["引发不稳定斜坡诱发因素", "trigger_unstable_slope_trigger_factors", "checkbox", ["地下水位上升", "降水", "融雪", "开挖扰动", "地震", "采矿", "新构造运动", "抽排水", "爆破", "河流侵蚀", "树木根劈", "融冰", "沟渠溢流或渗水", "温差变化", "加载", "机械震动"], "", "0", "一些说明"],
    ["引发不稳定斜坡威胁对象", "trigger_unstable_slope_threat_project", "text", [], "过往行人及车辆", "0", "一些说明"],
    ["引发不稳定斜坡威胁金额", "trigger_unstable_slope_threat_mount", "number", [], "10", "0", "万元"],
    ["引发不稳定斜坡威胁人数", "trigger_unstable_slope_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["引发不稳定斜坡发育程度", "trigger_unstable_slope_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //引发泥石流
    ["引发泥石流诱发因素", "trigger_debris_flow_trigger_factors", "checkbox", ["弃渣加载", "融雪", "融冰", "堰塞湖溢流", "地震","水库溢流或垮坝", "沟渠溢流", "降水", "植被破坏"], "", "0", "一些说明"],
    ["引发泥石流威胁对象", "trigger_debris_flow_threat_project", "text", [], "过往行人及车辆", "0", "一些说明"],
    ["引发泥石流威胁金额", "trigger_debris_flow_threat_mount", "number", [], "10", "0", "万元"],
    ["引发泥石流威胁人数", "trigger_debris_flow_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["引发泥石流发育程度", "trigger_debris_flow_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //引发岩溶塌陷
    ["引发岩溶塌陷诱发因素", "trigger_karst_subside_trigger_factors", "checkbox", ["地下水位变化", "地震", "降水","抽排水", "开挖扰动", "采矿", "机械震动", "加载"], "", "0", "一些说明"],
    ["引发岩溶塌陷威胁对象", "trigger_karst_subside_threat_project", "text", [], "过往行人及车辆", "0", "一些说明"],
    ["引发岩溶塌陷威胁金额", "trigger_karst_subside_threat_mount", "number", [], "10", "0", "万元"],
    ["引发岩溶塌陷威胁人数", "trigger_karst_subside_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["引发岩溶塌陷发育程度", "trigger_karst_subside_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //引发地裂缝
    ["引发地裂缝诱发因素", "trigger_ground_fissure_trigger_factors", "checkbox", ["地震", "新构造运动","抽排水"], "", "0", "一些说明"],
    ["引发地裂缝威胁对象", "trigger_ground_fissure_threat_project", "text", [], "过往行人及车辆", "0", "一些说明"],
    ["引发地裂缝威胁金额", "trigger_ground_fissure_threat_mount", "number", [], "10", "0", "万元"],
    ["引发地裂缝威胁人数", "trigger_ground_fissure_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["引发地裂缝发育程度", "trigger_ground_fissure_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //引发地面沉降
    ["引发地面沉降诱发因素", "trigger_subsidence_trigger_factors", "checkbox", ["新构造运动","抽排水","油气开采"], "", "0", "一些说明"],
    ["引发地面沉降威胁对象", "trigger_subsidence_threat_project", "text", [], "过往行人及车辆", "0", "一些说明"],
    ["引发地面沉降威胁金额", "trigger_subsidence_threat_mount", "number", [], "10", "0", "万元"],
    ["引发地面沉降威胁人数", "trigger_subsidence_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["引发地面沉降发育程度", "trigger_subsidence_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //遭受评估
    //遭受滑坡
    ["遭受滑坡威胁对象", "suffer_landslide_threat_project", "text", [], "工程本身及相关工作人员", "0", "一些说明"],
    ["遭受滑坡威胁金额", "suffer_landslide_threat_mount", "number", [], "10", "0", "万元"],
    ["遭受滑坡威胁人数", "suffer_landslide_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["遭受滑坡发育程度", "suffer_landslide_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //遭受崩塌
    ["遭受崩塌威胁对象", "suffer_collapse_threat_project", "text", [], "工程本身及相关工作人员", "0", "一些说明"],
    ["遭受崩塌威胁金额", "suffer_collapse_threat_mount", "number", [], "10", "0", "万元"],
    ["遭受崩塌威胁人数", "suffer_collapse_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["遭受崩塌发育程度", "suffer_collapse_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //遭受采空塌陷
    ["遭受采空塌陷威胁对象", "suffer_mine_subsidence_threat_project", "text", [], "工程本身及相关工作人员", "0", "一些说明"],
    ["遭受采空塌陷威胁金额", "suffer_mine_subsidence_threat_mount", "number", [], "10", "0", "万元"],
    ["遭受采空塌陷威胁人数", "suffer_mine_subsidence_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["遭受采空塌陷发育程度", "suffer_mine_subsidence_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //遭受不稳定斜坡
    ["遭受不稳定斜坡威胁对象", "suffer_unstable_slope_threat_project", "text", [], "工程本身及相关工作人员", "0", "一些说明"],
    ["遭受不稳定斜坡威胁金额", "suffer_unstable_slope_threat_mount", "number", [], "10", "0", "万元"],
    ["遭受不稳定斜坡威胁人数", "suffer_unstable_slope_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["遭受不稳定斜坡发育程度", "suffer_unstable_slope_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //遭受泥石流
    ["遭受泥石流威胁对象", "suffer_debris_flow_threat_project", "text", [], "工程本身及相关工作人员", "0", "一些说明"],
    ["遭受泥石流威胁金额", "suffer_debris_flow_threat_mount", "number", [], "10", "0", "万元"],
    ["遭受泥石流威胁人数", "suffer_debris_flow_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["遭受泥石流发育程度", "suffer_debris_flow_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //遭受岩溶塌陷
    ["遭受岩溶塌陷威胁对象", "suffer_karst_subside_threat_project", "text", [], "工程本身及相关工作人员", "0", "一些说明"],
    ["遭受岩溶塌陷威胁金额", "suffer_karst_subside_threat_mount", "number", [], "10", "0", "万元"],
    ["遭受岩溶塌陷威胁人数", "suffer_karst_subside_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["遭受岩溶塌陷发育程度", "suffer_karst_subside_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //遭受地裂缝
    ["遭受地裂缝威胁对象", "suffer_ground_fissure_threat_project", "text", [], "工程本身及相关工作人员", "0", "一些说明"],
    ["遭受地裂缝威胁金额", "suffer_ground_fissure_threat_mount", "number", [], "10", "0", "万元"],
    ["遭受地裂缝威胁人数", "suffer_ground_fissure_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["遭受地裂缝发育程度", "suffer_ground_fissure_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    //遭受地面沉降
    ["遭受地面沉降威胁对象", "suffer_subsidence_threat_project", "text", [], "工程本身及相关工作人员", "0", "一些说明"],
    ["遭受地面沉降威胁金额", "suffer_subsidence_threat_mount", "number", [], "10", "0", "万元"],
    ["遭受地面沉降威胁人数", "suffer_subsidence_threat_bodys", "number", [], "3", "0", "一些说明"],
    ["遭受地面沉降发育程度", "suffer_subsidence_maturity_level", "select", ["强发育", "中等发育", "弱发育"], "弱发育", "0", "一些说明"],
    ["防治措施", "prevention_and_control_measures", "checkbox", ["禁止采煤","采空区治理","保安煤柱","不稳定斜坡治理","崩塌治理","滑坡治理","排水措施","长期监测"], "", "0", "一些说明"],
    ["调查点坐标", "geological_hazard_investigation_point_coordinates", "textarea", [], "", "0", "每行一个点，\n8位经度在前，\n7位纬度在后，\n不要加序号"],
    ["甲方", "party_A", "text", [], "", "0", "一些说明"],
    ["乙方", "party_B", "text", [], "", "0", "一些说明"],
    //["选择地层", "stratum_lithology", "checkbox", ["太古界（Ar）","寒武系馒头组（∈1m）","奥陶系中统峰峰组（O2f）","石炭系中统本溪组（C2b）","石炭系上统太原组（C3t）","二叠系下统山西组（P1s）","第四系全新统（Q4）"], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
    ["", "", "", [], "", "0", "一些说明"],
  ];

export function App() {
  const { register, handleSubmit } = useForm();
  const [formData, setFormData] = useState({});

  const onSubmit = (data) => {
    setFormData(data);
  };

  const renderInput = (field) => {
    switch (field[2]) {
      case "text":
      case "date":
        return <input type={field[2]} {...register(field[1], { required: field[5] === "1" })} defaultValue={field[4]} />;
      case "select":
        return (
          <select {...register(field[1], { required: field[5] === "1" })} defaultValue={field[4]}>
            {field[3].map(option => <option key={option} value={option}>{option}</option>)}
          </select>
        );
      case "textarea":
        return <textarea {...register(field[1], { required: field[5] === "1" })} defaultValue={field[4]} />;
      case "number":
        return <input type={field[2]} {...register(field[1], { required: field[5] === "1" })} defaultValue={field[4]} />;
      case "file":
        return <input type={field[2]} {...register(field[1], { required: field[5] === "1" })} defaultValue={field[4]} />;      
      case "checkbox":
        return (
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
            {field[3].map(option => (
              <label key={option} style={{ display: "flex", alignItems: "center" }}>
                <input type="checkbox" value={option} {...register(`${field[1]}_${option}`)} />
                {option}
              </label>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "10px" }}>
      {data.map((field, index) => (
        <React.Fragment key={index}>
          <label>{field[0]}{field[5] === "1" && <span style={{ color: "red" }}>*</span>}</label>
          {renderInput(field)}
          <span>{field[6]}</span>
        </React.Fragment>
      ))}
      <input type="submit" />
      <pre>{JSON.stringify(formData, null, 2)}</pre>
    </form>
  );
}